<template>
  <div class="card v flex">
    <van-form @submit="onSubmit" label-width="8rem">
      <van-field readonly clickable name="calendar" :value="form._Time" label="记录时间" placeholder="点击选择记录时间" @click="showCalendar = true"  :rules="[{ required: true, message: '请选择记录时间' }]"/>
      <van-calendar v-model="showCalendar" @confirm="onCalendarConfirm" />
      <van-field readonly clickable name="datetimePicker" :value="form._houer" label="时间选择" placeholder="点击选择时间" @click="showPicker = true"  :rules="[{ required: true, message: '请选择时间' }]"/>
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker type="time" @confirm="onDateConfirm" @cancel="showPicker = false" />
      </van-popup>
      <van-field name="stepper" label="花费时间(单位:天)"  :rules="[{ required: true, message: '请填写花费时间' }]">
        <template #input>
          <van-stepper v-model="form.costTime" min="0" />
        </template>
      </van-field>
      <van-field name="stepper" label="剩余时间(单位:天)(0天代表任务完成)"  :rules="[{ required: true, message: '请填写剩余时间' }]">
        <template #input>
          <van-stepper v-model="form.remaining" min="0" />
        </template>
      </van-field>
      <van-field v-model="form.recInfo" rows="3" autosize label="记录信息" type="textarea" placeholder="请输入记录信息" />
      <!-- <van-field name="uploader" label="附件">
        <template #input>
          <van-uploader v-model="uploader" />
        </template>
      </van-field> -->

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { add } from "@/api/project/progress";

export default {
  data() {
    return {
      username: "",
      password: "",
      value: "",
      showCalendar: false,
      showPicker: false,
      form: {
        recTime: "",
        _Time: "",
        _houer: "",
        costTime: 0,
        remaining: 0,
        recInfo: "",
      },
      uploader: [],

    };
  },
  computed: {
    taskId() {
      return this.$route.params.taskId;
    },
  },
  mounted() {
    console.log( this.form.fileId," this.form.fileId");

  },
  methods: {
    onSubmit(values) {
      this.form.taskId=this.taskId
      console.log(this.form,"this.form");

      this.form.recTime=this.form._Time+" "+this.form._houer
      add(this.form).then
      (res => {
        this.$notify({ type: 'primary', message: '新增成功' });
        this.$router.back();
      })
      console.log("submit", values);
    },
    onCalendarConfirm(date) {
      this.form._Time = `${date.getFullYear() }-${date.getMonth() + 1}-${date.getDate()}`;
      this.showCalendar = false;
    },
    onDateConfirm(time) {
      this.form._houer = time;
      this.showPicker = false;
    },

  },
};
</script>

<style lang="scss">
.card {
  height: 100%;
  overflow-y: scroll;
  margin: 1rem;
  background-color: var(--background-color-lightest);
  border-radius: 10px;
}
</style>
